
import storeCode from '~/mixins/storeCode'

export default {
  name: 'MediSnapCarouselHomePageTabs',
  mixins: [
    storeCode,
  ],

  props: {
    api: {
      type: Object,
      default: null,
    },

    tabsComponentType: {
      type: String,
      default: '',
    },
  },

  computed: {
    slidesPerView () {
      switch (this.api.componentType) {
        case 'popular_sections_carousel':
          return {
            xxs: 1.5,
            xs: 1.5,
            sm: 2,
            md: 4,
            lg: 6,
            xl: 6,
          }

        case 'popular_products_tabs_carousel':
          return {
            xxs: 1.25,
            xs: 1.25,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
          }
      }
      return undefined
    },

    getTabs () {
      return this.api.children.find(item => ['popular_sections_carousel', 'tabs'].includes(item?.componentType))?.children
    },

    mainComponentType () {
      return this.api?.componentType
    },
  },

  methods: {
    getCarouselItems (data) {
      return data.find(({ componentType }) => componentType === 'carousel')?.children
    },
  },
}
